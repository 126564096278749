<template>
  <div class="top_Header">
    <header class="headers">
      <img src="../../../public/image/1.png" alt="" />
      <img
        class="imgSrc"
        src="../../../public/image/yunshuju_logo.png"
        alt=""
      />
    </header>
    <section class="sects">
      <img src="../../../public/image/zi.png" alt="" />
    </section>
    <aside class="content">
      <img src="../../../public/image/编组 10.png" alt="" />
    </aside>

    <div class="bgphoto">
      <img src="../../assets/bg.png" alt="" />
    </div>

    <div class="btns" @click="addTolist">
      <button>开始评价</button>
    </div>

    <div class="footer">
      <p>中国汽车售后服务质量监测大数据平台</p>
      <p style="display: block">提供技术支持</p>
    </div>
    <!-- <footer class="foters">
      <p>中国汽车售后服务质量监测大数据平台</p>
      <p style="display:block">提供技术支持</p> -->
    <!-- <span>提供技术支持</span> -->
    <!-- </footer> -->
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  props: {},
  data() {
    return {
      openid: "",
      userId: "",
    };
  },
  methods: {
    addTolist() {
      if (this.openid === undefined || this.userId === undefined) {
        this.$router.push("../Error_page");
        return;
      }

      // location.href = 'http://dw.cadayun.com/#/diaowen/utnhqttu'
      this.$router.push(
        `../Select?openid=${this.openid}&userId=${this.userId}`
      );
    },
  },
  computed: {},
  watch: {},
  created() {
    this.openid = this.$route.query.openid;
    this.userId = this.$route.query.userId;
    document.body.removeChild(document.getElementById("Loading"));

    // console.log(this.openid);
    // console.log(this.userId);
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.top_Header {
  width: 100%;
  height: 100%;

  // background-image: url('../../assets/bg.png');
  // background-repeat: no-repeat;
  .headers {
    display: flex;
    margin: 0px 27px;
    margin-top: 30px;
    align-items: center;

    .imgSrc {
      margin-left: 9px;
    }
  }

  .sects {
    width: 100%;
    height: 100%;
    margin: 0px 27px 45px;
    margin-top: 45px;
  }

  .content {
    width: 100%;
    height: 100%;
    margin: 0px 27px 95px;
  }

  .bgphoto {
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: -11;
    }
  }

  .btns {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 240px;
    button {
      width: 275px;
      height: 44px;
      background: #ffffff;
      border-radius: 28px;
      border: none;
      cursor: pointer;
      font-size: 22px;
      font-family: AlibabaPuHuiTiM;
      color: #005ae9;
      line-height: 44px;
      box-shadow: 2px 2px 1px 1px;
    }
  }

  // .foters {
  //   width: 100%;
  //   height:60px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   margin-top: 10px;

  //   p {
  //     height: 44px;
  //     font-size: 16px;
  //     font-family: AlibabaPuHuiTiR;
  //     color: #ffffff;
  //     line-height: 22px;
  //     text-align: center;
  //     display: block;
  //   }
  // }
  .footer {
    margin-top: 10px;
    p {
      width: 100%;
      height: 100%;
      font-size: 16px;
      font-family: AlibabaPuHuiTiR;
      color: #ffffff;
      line-height: 22px;
      text-align: center;
      display: block;
    }
  }
}
</style>